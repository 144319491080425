import SpinnerLegacy from '@pretto/bricks/components/loading/SpinnerLegacy'
import Text from '@pretto/bricks/components/typography/Text'

import range from 'lodash/range'
import Markdown from 'markdown-to-jsx'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import * as Charts from 'react-google-charts'

import * as S from './styles'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

const AUTH_KEY = 'AIzaSyBEGOPAjZyVm3axyAuWX8Vgsb7kr2DfeOg'
const SPREADSHEET_KEY = '1nQmWJIh0j2PiYsHlLV7GXyL9f6v1211PWBL8vf1OeOo'

const markdownOptions = {
  createElement(type, { href }, ...children) {
    if (type === 'a') {
      return (
        <a href={href} rel="noreferrer noopener" target="_blank">
          {children}
        </a>
      )
    }

    return React.createElement(Fragment, null, ...children)
  },
  forceBlock: true,
}

const Chart = ({ hAxisTitle, hideLegend, legendPosition, source, type, vAxisTitle, ...props }) => {
  const spinnerRef = useRef()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [values, setValues] = useState(null)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio <= 0) {
          return
        }

        observer.unobserve(spinnerRef.current)
        load()
      })
    })

    observer.observe(spinnerRef.current)

    return () => {
      if (!spinnerRef.current) {
        return
      }

      observer.unobserve(spinnerRef.current)
    }
  }, [])

  const load = async () => {
    const headers = new Headers()
    const url = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_KEY}/values:batchGet`)

    headers.append('Content-Type', 'application/json')

    url.searchParams.append('key', AUTH_KEY)
    url.searchParams.append('majorDimension', 'ROWS')
    url.searchParams.append('ranges', props.slug)

    try {
      const response = await fetch(url, { headers })
      const { valueRanges } = await response.json()

      const rawValues = valueRanges[0].values

      const formats = rawValues[1]
      const properties = rawValues[0]

      const length = Math.max(formats.length, properties.length)

      const values = [
        range(length).map(index => {
          const format = formats[index]

          switch (format) {
            case 'style':
              return { role: 'style' }

            default:
              return properties[index]
          }
        }),
        ...rawValues.slice(2).map(row =>
          row.map((cell, index) => {
            const format = formats[index]

            switch (format) {
              case 'number':
                // ignore US thousand separator and parse to float
                return parseFloat(cell.replace(/,/, ''))

              default:
                return cell
            }
          })
        ),
      ]

      setValues(values)
    } catch (error) {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoading) {
    return (
      <S.Loader ref={spinnerRef}>
        <S.LoaderContent>
          <SpinnerLegacy />
        </S.LoaderContent>
      </S.Loader>
    )
  }

  if (error || !values) {
    return null
  }

  const options = {
    ...props,
    legend: {
      position: hideLegend ? 'none' : legendPosition,
    },
    hAxis: { title: hAxisTitle },
    vAxis: { title: vAxisTitle },
  }

  return (
    <figure>
      <S.Chart>
        <S.Content>
          <Charts.Chart chartType={`${type}Chart`} data={values} height="100%" options={options} width="100%" />
        </S.Content>
      </S.Chart>

      {source && (
        <S.Source>
          <Text size="small" variant="neutral-1-60">
            Source : <Markdown options={markdownOptions}>{source}</Markdown>
          </Text>
        </S.Source>
      )}
    </figure>
  )
}

Chart.defaultProps = {
  hAxisTitle: '',
  source: '',
  hideLegend: false,
  legendPosition: 'right',
  vAxisTitle: '',
}

Chart.propTypes = {
  /** Piece of text that goes below the chart.*/
  source: PropTypes.string,
  /** Title of the horizontal axis */
  hAxisTitle: PropTypes.string,
  /** Whether or not the legend should be hidden. */
  hideLegend: PropTypes.bool,
  /** Legend position */
  legendPosition: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  /** Slug of the spreadsheet to use */
  slug: PropTypes.string.isRequired,
  /** Title of the chart. */
  title: PropTypes.string,
  /** Type of chart to use. */
  type: PropTypes.string.isRequired,
  /** Title of the vertical axis */
  vAxisTitle: PropTypes.string,
}

export default Chart
