import ChartComponent from '@pretto/bricks/website/shared/components/Chart'

import * as S from '../styles'

const Chart = props => (
  <S.BlockLarge>
    <ChartComponent {...props} />
  </S.BlockLarge>
)

export default Chart
